<template>
  <div>
    <a-card :title="$t('盘点记录详情')">
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />{{ $t("返回") }}</a-button>
      <a-spin :spinning="orderLoading">
        <a-descriptions bordered>
          <a-descriptions-item :label="$t('盘点单号')">
            {{ stockCheckRecordItem.stock_check_order_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('盘点仓库')">
            {{ stockCheckRecordItem.warehouse_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建时间')">
            {{ stockCheckRecordItem.create_time }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建人')">
            {{ stockCheckRecordItem.creator_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('备注')">
            {{ stockCheckRecordItem.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px;">
        <a-table rowKey="id" size="small" :columns="columns" :data-source="stockCheckRecordMaterialItems"
          :loading="materialLoading" :pagination="false">
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
  import { stockCheckRecordRetrieve, stockCheckRecordMaterials } from '@/api/stockCheck';
  import { columns } from './columns';

  export default {
    data() {
      return {
        columns,
        orderLoading: false,
        materialLoading: false,
        stockCheckRecord: undefined,
        stockCheckRecordItem: {},
        stockCheckRecordMaterialItems: [],
      }
    },
    methods: {
      initData() {
        this.stockCheckRecord = this.$route.query.id;

        this.orderLoading = true;
        stockCheckRecordRetrieve({ id: this.stockCheckRecord }).then(data => {
          this.stockCheckRecordItem = data;
        }).finally(() => {
          this.orderLoading = false;
        });

        this.materialLoading = true;
        stockCheckRecordMaterials({ id: this.stockCheckRecord }).then(data => {
          this.stockCheckRecordMaterialItems = data;
        }).finally(() => {
          this.materialLoading = false;
        });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>